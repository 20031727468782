import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import LogoLinks from '../components/LogoLinks';
import Testimonials from '../components/Testimonials';

const IndexPage = () => {
   return (
      <Layout>
         <Seo title='Message Sent' />
         <header className='w-full'>
            <div className='relative'>
               <div className='absolute inset-x-0' />
               <div className='relative h-full'>
                  <div className='absolute inset-0'>
                     <StaticImage
                        src='../images/Homepage-Just-A-Nibble-Hero.jpg'
                        quality='100'
                        className='h-full w-full object-cover'
                        alt='Just a Nibble Charcuterie board'
                     />
                     <div aria-hidden='true' className='opacity-40 absolute inset-0 bg-black' />
                  </div>
                  <div className='relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-24 '>
                     <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                        <span className='block header'>Thanks for </span>
                        <span className='block header'>Reaching Out</span>
                     </h1>
                     <hr className='border-brand border rounded w-1/2 mx-auto my-3' />
                     <p className='text-white text-base md:text-xl uppercase tagline'>Someone will reach out shortly</p>
                     <div className='mt-12 hidden md:block'>
                        <Link
                           to='/'
                           className='proxima uppercase text-base hover:bg-white font-bold border-2 z-50 border-brand md:px-8 md:py-4 py-2 px-4 my-6 tracking-widest text-brand'
                        >
                           Back Home
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         <Testimonials />
         <ImageGrid />
         <LogoLinks />
      </Layout>
   );
};

const ImageGrid = () => {
   return (
      <section className='relative w-full '>
         <div className='grid-cols-2 md:grid-cols-4 grid w-full mx-auto'>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Steak'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-1.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Charcuterie board'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-2.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Meatball appetizer'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-3.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Sliced Chicken Entree'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-4.jpg'
               />
            </div>
         </div>
         <div className='hidden md:grid grid-cols-4 w-full'>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Lamb Chop Entree'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-5.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Roasted Carrots'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-6.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Potatoe and Rosemary Side'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-7.jpg'
               />
            </div>
            <div className='overflow-hidden ease-in-out'>
               <StaticImage
                  className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
                  quality={75}
                  placeholder='blurred'
                  alt='Meatball appetizer'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-8.jpg'
               />
            </div>
         </div>
      </section>
   );
};

export default IndexPage;
